import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const About = () => {
  return (
    <>
      <Container className="d-flex justify-content-center align-items-center vh-100">
        <Row>
          <Col className="text-center">
            <h1>BLUE OCEAN WAVES</h1>
            <p>NATURE, TRAVEL</p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default About;
