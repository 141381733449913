import React, { useRef,useEffect  } from 'react';
import Slider from 'react-slick';
import image1 from '../Images/01hero.jpg';
import image2 from '../Images/02hero.jpg';
import image3 from '../Images/03hero.jpg';
import { Link } from 'react-router-dom'; 

const slides = [
  { id: 1, img: image1, title: 'LOST & FOUND',link: '/page' },
  { id: 2, img: image2, title: 'LOST & FOUND ' },
  { id: 3, img: image3, title: 'LOST & FOUND' },
  { id: 4, img: image1, title: 'LOST & FOUND ' },
];

const ImageGridSlider = () => {
  const sliderRef = useRef(null);

  const settings = {
    centerMode: true,
    centerPadding: '25%',  // For larger screens
    slidesToShow: 1,
    infinite: true,
    speed: 800,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: 'ease',
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 768, // Tablets and below
        settings: {
          centerPadding: '15%',  // Less padding on smaller screens
        },
      },
      {
        breakpoint: 480, // Mobile devices
        settings: {
          centerPadding: '10%',  // Even less padding for mobile
        },
      },
    ],
  };

  const handleWheel = (event) => {
    if (event.deltaY > 0) {
      sliderRef.current.slickNext();
    } else {
      sliderRef.current.slickPrev();
    }
  };
  useEffect(() => {
    // Attach the wheel event listener to the window object
    window.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      // Clean up the event listener on component unmount
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return (
    <div className="slider-container">
      <Slider ref={sliderRef} {...settings}>
        {slides.map(slide => (
          <div key={slide.id} className="slider-item">
             <Link to={slide.link}> {/* Each image points to a unique link */}
            <img src={slide.img} alt={slide.title} className="slider-image" />
            </Link>
            <div className="slide-caption">
              <h3>{slide.title}</h3>
            </div>
          </div>
        ))}
      </Slider>
     
    </div>
  );
};

export default ImageGridSlider;
