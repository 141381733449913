import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Story = () => {
  return (
    <>
      <Container className="d-flex justify-content-center align-items-center vh-100">
        <Row>
          <Col className="text-center">
            <h1>Story</h1>
            
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Story;
