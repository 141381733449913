import React, { useEffect, useState } from "react";
import {Container } from 'react-bootstrap';
import image1 from '../Images/01hero.jpg';
import image2 from '../Images/02hero.jpg';
import image3 from '../Images/03hero.jpg';



const Page = () => {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        // Trigger the animation when the component mounts
        setAnimate(true);
      }, []);
      const captions = ["This is a longer caption for Image 1 that spans two or three lines.", "Caption for Image 2", "Caption for Image 3"];

  return (
    <>
    <Container className='parallexScroll'>
    <div className={`gallery ${animate ? "zoom-in" : ""}`}>
          {[image1, image2, image3].map((image, index) => (
            <div className="pinned-image" key={index}>
              <img src={image} alt={`Image ${index + 1}`} />
              <div className="caption">{captions[index]}</div>
            </div>
          ))}
        </div>
  </Container>
    </>
  
  );
};

export default Page;
