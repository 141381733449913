import logo from './Images/logo.png';
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Container, Nav,Navbar } from 'react-bootstrap';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Main from './Components/Main';
import Menubar from './Components/Menubar';
import Portfolio from './Components/Portfolio';
import About from './Components/About';
import Story from './Components/Story';
import Contact from './Components/Contact';
import Page from './Components/Page';

function App() {
   // Define additional inline styles
   const buttonStyle = {
    backgroundColor: 'red',
   
  };
  return (
    <>
      <Router>
      <div className="d-flex flex-column min-vh-100 app-container">
      <Navbar expand="lg">
     
     

        <Nav className="mx-auto">
          <Navbar.Brand as={NavLink} to="/home">
            <img
              src={logo}
              alt="Logo"
              className="logo"
            />
          </Navbar.Brand>
        </Nav>
      
    </Navbar> 

      <Container fluid className="flex-grow-1 my-15">
      <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/home" element={<Main />} />
          <Route path="/story" element={<Story />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/page" element={<Page />} />
       
        </Routes>
      </Container>
    

      <Navbar className="footer-nav mt-auto bg-darks">
        <Container className="justify-content-center">
          <Nav className="w-58 justify-content-around">
      
      <Nav.Link as={NavLink} to="/portfolio" activeClassName="active-link">PORTFOLIO</Nav.Link>
      <Nav.Link as={NavLink} to="/about-us" activeClassName="active-link">ABOUT US</Nav.Link>
      <Nav.Link as={NavLink} to="/story" activeClassName="active-link">STORY</Nav.Link>
      <Nav.Link as={NavLink} to="/contact" activeClassName="active-link">CONTACT</Nav.Link>
     
      
          </Nav>
          
        </Container>
      </Navbar>
    </div>
      
    </Router>
    </>    
    
  );
}

export default App;
