import React, { useState } from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import FullScreenPopup from './FullScreenPopup';
import {Row, Col, Image } from 'react-bootstrap';
import ImageComponent from './ImageComponent';
import VideoModal from './VideoModal';
import logo from '../Images/logo.png';

const Main =()=>{

    const [showPopup, setShowPopup] = useState(false);
    const [showFullScreen, setShowFullScreen] = useState(true);


    const [imageShow, setImageShow] = useState(false);
    const handleImageClose = () => setImageShow(false);
    const handleImageShow = () => setImageShow(true);
 
  const handleClose = () => setShowPopup(false);

 

    return (<>
        <>
      
       
      <Container className="d-flex justify-content-center align-items-center vh-101">
      <br/>
      <Row className="w-100">
      <Col className="d-flex justify-content-center">
          <div className="centered-div">
          <video
       className="video-responsive"
        loop
        autoPlay
        muted={true} // Allows the video to play with sound
        controls // Optional, if you want to give users play/pause controls
        style={{ cursor: 'pointer' }} // Optional, visual feedback
      >
        <source src="/Videos/Clint_Coffe_Ad_V2.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
          </div>
        </Col>
      </Row> 
    </Container>
   

        
     
      

      <FullScreenPopup show={showPopup}  fullscreen={showFullScreen} handleClose={handleClose} />
    </>
    
    </>)
}

export default Main; 