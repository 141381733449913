import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';
const FullScreenPopup = ({ show, handleClose,fullscreen }) => {


    return (
        <>
        <CSSTransition
      in={show}
      timeout={500}
      classNames="slide"
      unmountOnExit
    >
      
      <Modal
        show={show}
        fullscreen={fullscreen}
        onHide={handleClose}
       dialogClassName="full-width-modal"
        aria-labelledby="full-width-modal-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">Full Screen Popup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Content Goes Here</h4>
          <p>This is a full-screen popup example.</p>
        </Modal.Body>
      </Modal>
       </CSSTransition>
       </>
    );
  };
  
  export default FullScreenPopup;